import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
import { ToolsIcon } from '@smallstep/step-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContentLink = makeShortcode("ContentLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Configure popular services to use mutual TLS`}</h2>
    <p>{`Mutual TLS (mTLS) is a security practice that provides encrypted communication between every workload and application in your infrastructure, regardless of location.`}</p>
    <p>{`The Hello mTLS project was created to increase awareness about public key infrastructure as a security solution for modern applications.`}</p>
    <p>{`Use our interactive tool to learn how to configure popular technologies to use mTLS, copy and paste commands for NGINX, axios, node.js, kafka, MySQL, PostgreSQL, golang, ruby, and more.`}</p>
    <ContentLink tile icon={<ToolsIcon mdxType="ToolsIcon" />} href="/hello-mtls#configuration-examples" mdxType="ContentLink">
  Generate your configuration &gt;
    </ContentLink>
    <h3>{`Teams use Mutual TLS because:`}</h3>
    <ul>
      <li parentName="ul">{`mTLS is fast, easy to use, and works everywhere you do`}</li>
      <li parentName="ul">{`You can avoid complicated VPN/VPC configurations`}</li>
      <li parentName="ul">{`It allows you to scale end-to-end encryption anywhere`}</li>
      <li parentName="ul">{`and much more ...`}</li>
    </ul>
    <h2>{`Features`}</h2>
    <h3>{`Works Everywhere`}</h3>
    <p>{`Nearly every language, server, database, client (and more!) already offers battle-tested TLS/SSL support. mTLS lets you replace countless fragile and frustrating bits of security infrastructure with a single consistent solution that's easier to understand, easier to scale, and easier to operate.`}</p>
    <h3>{`No VPNs`}</h3>
    <p>{`mTLS eliminates the need to use network boundaries, VPNs, and IP block/allow lists to provide access to your applications. There is usually no need to do anything more than configure the tools and libraries you're already using in your applications to speak TLS.`}</p>
    <h3>{`Massive Scalability`}</h3>
    <p>{`Using proven cryptography and mTLS your applications talk directly to each other rather than bogging down inside a network tunnel or calling back to a central authority. Proven inside the largest distributed systems, mTLS makes it easy to scale your infrastructure securely.`}</p>
    <ContentLink tile icon={<ToolsIcon mdxType="ToolsIcon" />} href="/hello-mtls#configuration-examples" mdxType="ContentLink">
  Generate your configuration &gt;
    </ContentLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      